@tailwind base;
@tailwind components;
@tailwind utilities;

// ===============================Home Page=========================================
/* tailwind.css */

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("../Fonts/Inter.css");

.main {
  height: 100vh;
}
main {
  @apply py-5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.custom-table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 80%;
  table-layout: fixed;
}

.custom-table tbody {
  background-color: transparent;
}

.custom-table thead tr {
  background-color: var(--light-blue);
  & th {
    background: #212529;
    color: #b69a5e;
  }
}
.Actions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-table th:first-child {
  width: 60px;
}

.custom-table tr {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 0.35em;
  vertical-align: middle;
}

.custom-table th,
.custom-table td {
  padding: 0.5em 0.575em;
  text-align: center;
}

.custom-table th {
  font-size: 0.9em;
  text-transform: capitalize;
}

@media only screen and (max-width: 1200px) {
  .btn-font-size {
    font-size: 12px;
  }
}

@media screen and (max-width: 991px) {
  .custom-table {
    border: 0;
    max-width: 100%;
  }

  .custom-table th,
  .custom-table td {
    padding: 1em;
    border-top: none;
  }

  .custom-table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .custom-table tr {
    /* border-bottom: 3px solid #fff; */
    display: block;
    margin-bottom: 2em;
  }

  .custom-table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
    max-width: 100%;
  }

  .custom-table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: 500;
    text-transform: uppercase;
  }

  .custom-table td:first-child {
    display: none;
  }

  .custom-table td:last-child {
    border-bottom: 0;
  }

  .proposal-table {
    width: 790px;
  }
}

@media screen and (max-width: 768px) {
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-left: 48px;
  }

  .contentWidth {
    max-width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .custom-table td::before {
    content: " ";
  }

  .custom-table td {
    text-align: center;
  }
}

@media screen and (max-width: 480px) {
  .custom-table td::before {
    content: " ";
  }

  .custom-table td {
    text-align: center;
  }

  .pdf-details h3 {
    font-size: 16px;
  }

  .pdf-details p {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .onoffswitch-label:before {
    width: 15px;
    height: 15px;
    top: 4px;
    margin-left: -4px;
    right: 45px;
  }

  .onoffswitch-label {
    height: 15px;
    line-height: 15px;
  }
}

.app-wrapper {
  max-width: 100%;
  min-height: 100vh;
  width: 100vw;
}

button {
  @apply bg-green-500 text-white border-none px-4 py-2 mr-4 cursor-pointer;
}

button:hover {
  @apply bg-green-600;
}

header {
  @apply text-white p-4 px-16 text-center flex;
  background-color: #212529 !important;
  justify-content: space-between;
  align-items: center;
  & h1 {
    @apply font-bold text-3xl mb-2;
  }
  & .roundimage {
    @apply w-10 h-10 p-1 rounded-full ring-2 ring-gray-300 dark:ring-gray-500 cursor-pointer flex justify-center;
    align-items: center;
  }
}

header h1 {
  @apply m-0 cursor-pointer;
}

header div {
  // @apply mt-4;
}

button {
  font-weight: 500;
  color: #1f2937;
  background: #b69a5e;
  border-radius: 8px;
}

button:hover {
  background: #607181;
}
// ===============================================Header==========================================

.headerlogo {
  height: 5rem;
}

// ===============================================Dashboard==========================================
.Tableaction {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Addbutton {
  @apply w-full flex justify-end mt-2 me-16;
  padding-right: 11rem;
}

// =======================================Loader =================================================

#loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #1f2937;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

// =========================== DropDown===============================

.Taildropdown {
  @apply z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600;
  position: absolute;
  width: max-content;
  left: -148px;
  top: 51px;

  & .first {
    @apply px-4 py-3 text-sm text-gray-900 dark:text-white;
  }
}

/* Sticky Pagination */

.Sticky-Pagination {
  padding-left: 0rem;
  gap: 3rem;
  border-radius: 0rem 0rem 0.25rem 0.25rem;
  border: 1px solid #dadce0;
  background: #fff;
  // position: fixed !important;
  bottom: 0;
  display: flex;
  text-align: center;
  justify-content: end;
  /* left: calc(100%-100vh); */
  width: -webkit-fill-available;
  z-index: 1;
  /* justify-content: space-around; */
  /* max-width: calc(100%-100vh); */
}
.Sticky-Pagination .page-item {
  margin-left: 1rem;
}
.Sticky-Pagination .pagination {
  margin: 0;
  padding: 11px;
}
.Sticky-Pagination .page-item.active .page-link:focus,
.Sticky-Pagination .page-link:focus {
  color: black;
  border-radius: 0.25rem;
  background: transparent;
  outline: none !important;
  box-shadow: none !important;
}
.page-item .active {
  & .page-link {
    background-color: #b69a5e;
    color: #1f2937;
  }
}
.Sticky-Pagination .page-item.active .page-link-2:focus,
.Sticky-Pagination .page-link-2:focus {
  color: black !important;
  border-radius: 0.25rem;
  /* background: #753cb7; */
  background: transparent !important;
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}
.Sticky-Pagination .page-link {
  border-radius: 0.25rem;
  border: 1px solid #dadce0;
  background: #fff;
}
.page-link-2 {
  border: none !important;
}
.Sticky-Pagination .page-item .selected {
  background: transparent;
  color: white;
}
.Sticky-Pagination .page-item.active .page-link {
  z-index: 1;
  color: #1f2937 !important;
  background: #b69a5e !important;
  border-color: white !important;
}
.Sticky-Pagination .page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: black;
  background-color: #fff;
}

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a {
  background-color: #47ccde;
  border-color: #47ccde;
  color: #fff;
}
.pagination > li > a {
  border: 1px solid #47ccde;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #47ccde;
  border-color: #47ccde;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #47ccde;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.pagination li a:active,
.pagination li a:hover {
  background-color: #47ccde;
  color: white !important;
  border: 1px solid black;
}

.paginationcenter {
  display: flex;
  align-items: center;
}
.Downloadall {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 2rem;
  & .filterdata {
    gap: 2rem;
  }
}

/* End */
/* Filter */
label {
  font-size: medium;
}
input {
  cursor: auto;
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  padding: 0 30px;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  // transition: all 0.3s ease;
  height: 41px;
}
input:focus {
  --tw-ring-color: none !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}
input[type="date"] {
  cursor: text;
}
/* Reset <select> defaults */
select {
  cursor: pointer;
  border: 0;
  outline: 0;
  background: none;
  color: inherit;
  outline: none;
  box-shadow: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-color: #6b7280;
}
select::-ms-expand {
  display: none;
}
.select {
  cursor: pointer;
  position: relative;
  display: flex;
  width: 100%;
  height: 41px;
  overflow: hidden;
}
.select:focus,
select:focus-visible {
  border-bottom: 2px solid;
  transition: all 0.3s ease;
  border-color: #1c64f2;
}
select option:hover {
  background-color: #b69a5e;
}
