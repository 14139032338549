@media (min-resolution: 120dpi) and (max-resolution: 130dpi) {
    /* .newSideheaderMain {
      zoom: 100%;
    } */
    header,.zoomclass {
        zoom: 75%;
    }
    /* .fullHeightLogin{
        zoom: 85%;
    }
    .fullHeightLogin .d-flex .align-items-center .justify-content-center .row{
        zoom: 100% !important;
    } */
  }